* {
  font-size: 1.2rem;
  font-family: sans-serif;
}

body {
  background-color: black;
  margin: 0;
  padding: 0;
}

input[type=text] {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4) inset, 5px 5px 10px rgba(0, 0, 0, 0.25);
  padding: 2px;
  text-align: center;
}

button, input[type=submit] {
  background-color: #444;
  border: 1px solid black;
  border-radius: 3px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  color: white;
  cursor: pointer;
  margin: 5px;
  padding: 2px 8px;
  transition: all 200ms;
}

button:hover, input[type=submit]:hover {
  background-color: #ddd;
  color: black;
}

.App {
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.App > * {
  margin: 20px;
}

.background {
  background-image: url("https://picsum.photos/1000");
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  margin: 0;
  padding: 0;
  filter: blur(5px);
}

.wheel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.wheel .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid black;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  color: white;
  min-width: 480px;
  min-height: 200px;
}

.wheel .card .next-user {
  font-size: 1.8em;
}

.wheel .card .result {
  font-size: 1.3em;
}

.wheel .card .result * {
  font-size: 1.3em;
}

.userlist {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.userlist > * {
  background-color: black;
  border-radius: 50px;
  color: white;
  margin: 2px;
  padding: 2px 8px;
}

.userlist button {
  background-color: transparent;
  color: gray;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin-left: 5px;
}